import React from "react"
import Layout from "../components/layout"
import BlogRoll from "../components/blogroll"

export default () => {
  return (
    <Layout>
      <BlogRoll />
    </Layout>
  )
}